
import Vue, { PropType } from "vue";

import { Cancel } from "@/interfaces";

export default Vue.extend({
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  computed: {}
});
