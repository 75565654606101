
import Vue, { PropType } from "vue";

import { Cancel, Process } from "@/interfaces";

import http from "@/http";

import MyHistory from "@/components/cancel/MyHistory.vue";

export default Vue.extend({
  components: {
    MyHistory
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  computed: {
    processes() {
      const processes: Process[] = [];

      this.$store.state.processes.forEach((process: Process) => {
        if (this.cancel) {
          const found = this.cancel.processes.find((element: Process) => {
            return process.id === element.id;
          });

          if (found === undefined) {
            processes.push(process);
          }
        }
      });

      return processes;
    }
  },
  methods: {
    async done(process: Process) {
      const { id } = this.cancel;

      const url = `cancels/${id}/processes`;

      const { data } = await http.post<Process[]>(url, {
        process_id: process.id
      });

      this.cancel.processes = [...data];
    },
    async remove(process: Process) {
      const { id } = this.cancel;

      const url = `cancels/${id}/processes/${process.id}`;

      await http.delete(url);

      this.cancel.processes = this.cancel.processes.filter(element => {
        return element.id !== process.id;
      });
    }
  }
});
