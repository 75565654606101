
import Vue, { PropType } from "vue";

import { Cancel, Snackbar, Room, Building } from "@/interfaces";

import http from "@/http";

import MyOverview from "@/components/cancel/MyOverview.vue";

import { mdiDotsVertical } from "@mdi/js";

interface DataType {
  picker: string;
  dialogDate: boolean;
  dialogCompany: boolean;
  dialogRoom: boolean;
  company_id: number;
  room_id?: number;
  rooms: Room[];
}

export default Vue.extend({
  components: {
    MyOverview
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  data(): DataType {
    return {
      picker: "",
      dialogDate: false,
      dialogCompany: false,
      dialogRoom: false,
      company_id: 0,
      room_id: undefined,
      rooms: []
    };
  },
  computed: {
    title(): string {
      return `# ${this.cancel.id} ${this.cancel.room.building.name} ${this.cancel.room.display_number}`;
    },
    icon() {
      return {
        mdiDotsVertical
      };
    },
    companies() {
      return [
        {
          id: 0,
          name: "--"
        },
        ...this.$store.state.companies
      ];
    }
  },
  async mounted() {
    if (this.cancel.company) {
      this.company_id = this.cancel.company.id;
    }

    const url = `buildings/${this.cancel.room.building_id}`;

    const { data } = await http.get<Building>(url);

    this.rooms = data.rooms;
  },
  methods: {
    async input() {
      this.dialogDate = false;

      if (window.confirm("本当に変更しますか?") === false) {
        return;
      }

      const { id } = this.cancel;

      const url = `cancels/${id}`;

      const moving_out_date = this.picker;

      const { data } = await http.put<Cancel>(url, {
        moving_out_date
      });

      const snackbar: Snackbar = {
        show: true,
        content: "変更しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: "/cancels"
      });
    },
    async handleUpdate(cancel: Cancel) {
      if (window.confirm("立ち会いなしに設定しますか?") === false) {
        return;
      }
      const url = `cancels/${cancel.id}`;

      const { data } = await http.put<Cancel>(url, {
        presence_required: 0
      });

      const snackbar: Snackbar = {
        show: true,
        content: "変更しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: "/cancels"
      });
    },
    async handleCompanyChange() {
      this.dialogCompany = false;

      const { id } = this.cancel;

      const url = `cancels/${id}`;

      const { data } = await http.put<Cancel>(url, {
        company_id: this.company_id === 0 ? null : this.company_id
      });

      const snackbar: Snackbar = {
        show: true,
        content: "変更しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: "/cancels"
      });
    },
    async handleRoomChange() {
      this.dialogRoom = false;

      if (this.room_id == null) {
        return;
      }

      const { id } = this.cancel;

      const url = `cancels/${id}`;

      const { data } = await http.put<Cancel>(url, {
        room_id: this.room_id
      });

      const snackbar: Snackbar = {
        show: true,
        content: "変更しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: "/cancels"
      });
    },
    async handleCancel(cancel: Cancel, force: boolean) {
      const confirm = force ? "削除" : "キャンセル";

      if (window.confirm(`本当に${confirm}しますか?`) === false) {
        return;
      }

      const url = `cancels/${cancel.id}`;

      const data: any = {};

      if (force) {
        data.force = true;
      }

      await http.delete(url, {
        data
      });

      const snackbar: Snackbar = {
        show: true,
        content: `${confirm}しました`,
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: "/cancels"
      });
    },
    async handleLogin() {
      if (!this.cancel.user) {
        return;
      }

      const { data } = await http.get(`users/${this.cancel.user.id}`);

      window.location.href = data.login_link;
    }
  }
});
