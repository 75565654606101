
import Vue, { PropType } from "vue";

import MyOverviewCard from "@/components/cancel/MyOverviewCard.vue";
import MyDetail from "@/components/cancel/MyDetail.vue";
import MyHistoryCard from "@/components/cancel/MyHistoryCard.vue";
import MyAppointmentsCard from "@/components/cancel/MyAppointmentsCard.vue";

import { Breadcrumb, Cancel } from "@/interfaces";

import { mdiPrinter } from "@mdi/js";

export interface DataType {}

export default Vue.extend({
  components: {
    MyOverviewCard,
    MyDetail,
    MyAppointmentsCard,
    MyHistoryCard,
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true,
    },
  },
  data(): DataType {
    return {};
  },
  computed: {
    icon() {
      return {
        mdiPrinter,
      };
    },
    id(): number {
      const { id } = this.$route.params;

      return +id;
    },
    breadcrumbs(): Breadcrumb[] {
      if (this.cancel == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "解約",
          disabled: false,
          to: "/cancels",
        },
        {
          text: `# ${this.cancel.id} ${this.cancel.room.building.name} ${this.cancel.room.display_number}`,
          disabled: true,
          to: `/cancel/${this.id}`,
        },
      ];
    },
  },
});
