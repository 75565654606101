
import Vue, { PropType } from "vue";

import { Cancel, Appointment } from "@/interfaces";

import http from "@/http";
import { mdiPlus, mdiCheck, mdiCalendar } from "@mdi/js";

export default Vue.extend({
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      date: "",
      time: ""
    };
  },
  computed: {
    icon() {
      return {
        mdiPlus,
        mdiCheck,
        mdiCalendar
      };
    },
    items() {
      return [
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00"
      ];
    },
    isValid(): boolean {
      return this.date.length > 0 && this.time.length > 0;
    },
    hasFixed(): boolean {
      return this.cancel.appointments.some(appointment => {
        return appointment.is_fixed;
      });
    },
    fixed(): Appointment | undefined {
      return this.cancel.appointments.find(appointment => {
        return appointment.is_fixed;
      });
    }
  },
  methods: {
    async click(appointment: Appointment) {
      if (window.confirm("本当に確定しますか?") === false) {
        return;
      }

      const url = `/appointments/${appointment.id}`;

      const { data } = await http.put(url, {
        is_fixed: true
      });

      const index = this.cancel.appointments.findIndex(element => {
        return element.id === appointment.id;
      });

      this.cancel.appointments.splice(index, 1, data);
    },
    async submit() {
      const url = `/cancels/${this.cancel.id}/appointments`;

      const datetime = `${this.date} ${this.time}`;

      const { data } = await http.post(url, {
        datetime
      });

      this.cancel.appointments.push(data);

      this.date = "";
      this.time = "";
    }
  }
});
