
import Vue, { PropType } from "vue";

import { Process } from "@/interfaces";

import { mdiDelete } from "@mdi/js";

export default Vue.extend({
  props: {
    processes: {
      type: Array as PropType<Process[]>,
      required: true
    }
  },
  computed: {
    icon() {
      return { mdiDelete };
    }
  },
  methods: {
    click(process: Process) {
      this.$emit("remove", process);
    }
  }
});
