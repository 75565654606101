
import Vue, { PropType } from "vue";

import { Cancel, Snackbar } from "@/interfaces";

import http from "@/http";

import MyAppointments from "@/components/cancel/MyAppointments.vue";

export default Vue.extend({
  components: {
    MyAppointments
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  computed: {
    hasFixed(): boolean {
      return this.cancel.appointments.some(appointment => {
        return appointment.is_fixed;
      });
    }
  },
  methods: {
    click() {
      this.cancel.appointments.forEach(appointment => {
        appointment.is_fixed = false;
      });
    },
    async remove() {
      if (!window.confirm("本当に削除しますか?")) {
        return;
      }
      for (const appointment of this.cancel.appointments) {
        const url = `/appointments/${appointment.id}`;

        await http.delete(url);
      }

      this.cancel.appointments = [];

      const snackbar: Snackbar = {
        show: true,
        content: "削除しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);
    }
  }
});
